export const products = [
  {
    id: 1,
    img: "https://images.pexels.com/photos/4038866/pexels-photo-4038866.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    link: "https://cafe-three.vercel.app/",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/5717421/pexels-photo-5717421.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    link: "https://todolist-9j1lmxl7s-raichung.vercel.app/",
  },
  {
    id: 3,
    img: "https://images.pexels.com/photos/7234382/pexels-photo-7234382.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    link: "https://raichung.github.io/popcorn/",
  },
  
];
